<template>
    <section id="breakdown-list">
      <card icon="band-aid"
            :headline="$t('breakdowns.breakdownList.infoboxHeadline')"
            :description="$t('breakdowns.breakdownList.infoboxDescription')"
      >

      </card>
    </section>
</template>

<script>
    export default {
        name: "BreakdownList"
    }
</script>

<style scoped lang="scss">

</style>
